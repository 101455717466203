<template>
  <div class="mb-5 text-nowrap border-bottom overflow-auto">
    <router-link to="/manage-brand" class="btn btn-wz-tab">List Brands</router-link>
    <router-link :to="$route.params.id ? `/edit-brand/${$route.params.id}` : '#'" :disabled="!$route.params.id" class="btn btn-wz-tab">Edit Brand</router-link>
  </div>
</template>
<script>
export default {
  name: "BrandNavTab",
}
</script>
