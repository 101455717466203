<template>
  <div class="card">
    <div class="d-flex align-items-center mb-3">
      <div class="brand-logo-container">
        <div class="brand-logo-new" :style="model ? `--background: url('${model.image_banner_square}');` : ''">
          <div class="brand-logo-prime" v-if="model.check_prime_merchant"></div>
        </div>
        <!-- <div class="brand-logo-new" :style="{'--background': `url('${model.image_url}')`}"></div> -->
      </div>
      <div class="brand-name-new pl-3">
        <router-link :to="model ? '/edit-brand/' + model.id : '#'" class="text-black" @click.native="$store.dispatch('setEditBrand', model)">
          <h2 class="roboto mb-1">{{model && model.name ? model.name.trim() : "Brand Name"}}</h2>
        </router-link>
        <p class="text-grey m-0">{{model && model.description ? model.description.trim() : ""}}</p>
      </div>
      <fawesome-pro icon="chevron-right" variant="far" size="2x"
        :to="model ? '/edit-brand/' + model.id : '#'" class="ml-auto text-black" @click.native="$store.dispatch('setEditBrand', model)"
      />
    </div>
    <div v-if="model.highlights" class="row no-gutters">
      <div class="col px-1" :class="{'d-none d-lg-block' : i > 0}" v-for="(highlight, i) in [...model.highlights, ...Array(3 - model.highlights.length).fill(null)]" :key="i">
        <!-- <a v-if="highlight" :href="(highlight.link_url && !/^https?:\/\//.test(highlight.link_url) ? `https://${highlight.link_url}` : highlight.link_url) || (highlight.product_id || (highlight.product && highlight.product.id)? `https://${apiUrl.includes('staging') ? 'staging.' : ''}whizliz.com/product/${highlight.product_id || highlight.product.id}` : '#')" -->
        <a v-if="highlight" :href="(highlight.link_url && !/^https?:\/\//.test(highlight.link_url) ? `https://${highlight.link_url}` : highlight.link_url) || ((highlight.product && highlight.product.slug) ? `https://${apiUrl.includes('staging') ? 'staging.' : ''}whizliz.com/product/${highlight.product.slug}` : '#')"
          :class="{'d-none' : !highlight.status}"
          target="_blank"
        >
          <img ref="highlights" :src="highlight.image_url" class="rounded img-fluid" @error="replaceByDefaultImage">
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "BrandShowcase",
  props: ["brand"],
  data() {
    return {
      model: this.brand,
    }
  },
  computed: {
    apiUrl() {
      return process.env.VUE_APP_API_URL;
    }
  },
  mounted() {
    this.checkHighlights()
  },
  methods: {
    checkHighlights() {
      const highlights = this.model.highlights
      if (highlights) {
        localStorage.setItem("highlights", JSON.stringify(highlights))
        for (const i in highlights)
          this.getProductImg(i)
      }
      else setTimeout(() => this.checkHighlights(), 300)
    },
    async getProductImg(index) {
      const highlight = this.model.highlights[index]
      const condition = !highlight.image_url || /(ne(t|t\/)|co(m|m\/))$/.test(highlight.image_url)
      const productId = highlight.product_id || highlight.product?.id
      if (condition && productId) {
        try {
          const res = await this.$api.product.getProductDetail(productId)
          if (res.status === 200) {
            const element = this.$refs.highlights[index]
            element.src = res.data.data.image_url
            element.classList.remove("p-4", "p-lg-5")
          }
        } catch (e) {
          console.error(e);
        }
      }
    },
    replaceByDefaultImage(event) {
      event.target.src = require('@/assets/img/default-image.png')
      event.target.classList.add("p-4", "p-lg-5")
    }
  },
  watch: {
    brand(newVal) {
      this.model = newVal
      if (newVal.highlights) {
        const oldHighlights = JSON.parse(localStorage.getItem("highlights"))
        const newHighlights = newVal.highlights
        for (const i in newHighlights) {
          if (oldHighlights[i]?.product_id != newHighlights[i].product_id) return this.checkHighlights()
        }
      }
    },
  }
}
</script>
<style scoped>
.card {
  padding: 1rem;
  background: #f3f3f4;
  border: none;
  border-radius: 1rem;
}
.brand-logo-container {
  min-width: 72px;
  min-height: 72px;
}
.brand-logo-new {
  position: relative;
  width: 100%;
  padding-top: 100%;
  border-radius: 50%;
  background-color: white;
  background-image: var(--background);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.brand-logo-prime {
  background: url('../../assets/img/icon_prime_merchant.svg');
  position: absolute;
  top: -3px;
  right: -3px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
}
.brand-name-new a:hover {
  text-decoration: none;
}
.brand-name-new h2 {
  font-size: 1.5rem;
  cursor: pointer;
}
.brand-name-new p {
  cursor: text;
}
.brand-highlight-thumb {
  display: flex;
  background: #fff;
  border-radius: .5rem;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.rounded {
  border-radius: .5rem !important;
}
</style>
